import { Code, ConnectError } from '@connectrpc/connect';
import type { MetaFunction } from 'react-router';
import { data, redirect } from 'react-router';
import { useLoaderData } from 'react-router';
import { listHorses } from '@/api_clients/trainers/horse_client';
import { getMe } from '@/api_clients/trainers/user_client';
import { Loading } from '@/components/loading';
import { HorseListPage } from '@/components/trainers/horses/list_horse';
import { authenticate } from '@/utils/session.server';

export const meta: MetaFunction = () => {
  return [{ title: 'EQUTUM | 所属馬一覧' }];
};

export const loader = async ({ request }: { request: Request }) => {
  const { idToken, headers } = await authenticate(request);
  try {
    const [horseList, me] = await Promise.all([await listHorses(idToken), getMe(idToken)]);
    return data(
      {
        horses: horseList,
        hasHnContract: me.contracts?.hasHnContract ?? false,
        hasOrmContract: me.contracts?.hasOrmContract ?? false,
      },
      { headers }
    );
  } catch (error) {
    if (error instanceof ConnectError) {
      if (error.code === Code.Unauthenticated) {
        return redirect('/logout');
      }
    }
  }
};

export default function Page() {
  const data = useLoaderData<typeof loader>();
  if (!data) {
    return <Loading />;
  }
  const { horses, hasHnContract, hasOrmContract } = data;

  return (
    <HorseListPage horses={horses} hasHnContract={hasHnContract} hasOrmContract={hasOrmContract} />
  );
}
